// extracted by mini-css-extract-plugin
export const root = "PlasmicFeatureCard-module--root--pXa-D";
export const root__long = "PlasmicFeatureCard-module--root__long--Z3-XW";
export const box__nvU7X = "PlasmicFeatureCard-module--box__nvU7X--nKlqC";
export const box___08Tqt = "PlasmicFeatureCard-module--box___08Tqt--de6Vv";
export const box__uNrW3 = "PlasmicFeatureCard-module--box__uNrW3--3s3vC";
export const box__long__uNrW3GgthD = "PlasmicFeatureCard-module--box__long__uNrW3GgthD--3sU8h";
export const slotTitle = "PlasmicFeatureCard-module--slotTitle--ikjab";
export const slotDescription = "PlasmicFeatureCard-module--slotDescription--qbOZz";
export const slotDescription__long = "PlasmicFeatureCard-module--slotDescription__long--2x1ry";
export const box__vMPh = "PlasmicFeatureCard-module--box__vMPh--270Qg";
export const box__long__vMPhGgthD = "PlasmicFeatureCard-module--box__long__vMPhGgthD--2xFtY";