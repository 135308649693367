// extracted by mini-css-extract-plugin
export const root = "PlasmicTopSection-module--root--8bAT5";
export const box___35AJo = "PlasmicTopSection-module--box___35AJo--2kl9Q";
export const box__jOamN = "PlasmicTopSection-module--box__jOamN--8qeO-";
export const box__iWrz = "PlasmicTopSection-module--box__iWrz--3Drad";
export const box__y7IZq = "PlasmicTopSection-module--box__y7IZq--2zqKJ";
export const box___8AzHj = "PlasmicTopSection-module--box___8AzHj--3ZBhl";
export const box__qpES = "PlasmicTopSection-module--box__qpES--3149w";
export const box__vXQy = "PlasmicTopSection-module--box__vXQy--1yQyV";
export const linkButton__ePvnf = "PlasmicTopSection-module--linkButton__ePvnf--21S_R";
export const linkButton__fjMoV = "PlasmicTopSection-module--linkButton__fjMoV--11W6P";
export const box__ujdc2 = "PlasmicTopSection-module--box__ujdc2--9rCuG";
export const img = "PlasmicTopSection-module--img--22FGd";